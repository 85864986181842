import { serverBaseUrl, HttpException, XHR_CONTENT_TYPE, XHR_REQUEST_TYPE } from '../constants'

import { APIRequestPayload, APIResponse } from '@frontend/'

import { DialogMessageType } from '@/composition/dialog'
import useDialogStore from '@/composition/dialog'
import { parseWithDate } from '@/utilities'

interface ServiceInterface {
  base: {
    getEncodedParams: (query: Record<string, string>) => string
    apiRequest: <Req, Res>(
      {
        errorCode,
        route,
        method,
        query, // A dictionary containing values to convert to a URL query string (e.g. ?a=yyy&b=zzz)
        body, // Can be FormData, JSON, text, or Object (unknown type). Use contentType to specify which
        headers,
        credentials,
        contentType, // Chosen using XHR_CONTENT_TYPE enumerator. Defaults to 'application/json'
        baseURL,
        requiresFeedback,
      }: APIRequestPayload<Req>,
      callback?: () => void,
    ) => Promise<APIResponse<Res>>
  }
}

function useApiService(): ServiceInterface {
  const dialogStore = useDialogStore()

  const getSuccessTag = () => {
    switch (navigator.language) {
      case 'nb-NO':
      case 'nn-NO':
      case 'no':
        return 'Vellykket!'
      default:
        return 'Success!'
    }
  }

  const base = {
    getEncodedParams: (query: Record<string, string>): string => {
      // Encode query and body
      let qs = ''
      const queryKeys = query ? Reflect.ownKeys(query).map((k) => k.toString()) : []
      if (query && queryKeys.length > 0) {
        qs += '?'
        queryKeys.forEach((key, index) => {
          qs += `${key}=${query[key]}`
          qs += index < queryKeys.length - 1 ? '&' : ''
        })
      }
      return encodeURI(qs)
    },

    /**
     * apiRequest
     * prepares a xhrhttprequest according to the given parameters
     * @param param0
     * @returns
     */
    apiRequest: <Req, Res>(
      {
        errorCode,
        route,
        method,
        query, // A dictionary containing values to convert to a URL query string (e.g. ?a=yyy&b=zzz)
        body = '', // Can be FormData, JSON, text, or Object (unknown type). Use contentType to specify which
        headers,
        credentials = true,
        contentType, // Chosen using XHR_CONTENT_TYPE enumerator. Defaults to 'application/json'
        baseURL,
        requiresFeedback = false,
        convertDates = false,
      }: APIRequestPayload<Req>,
      callback?: () => void,
    ): Promise<APIResponse<Res>> => {
      // Set token if available
      const ct: XHR_CONTENT_TYPE = contentType || XHR_CONTENT_TYPE.JSON
      const _baseUrl = baseURL !== undefined ? baseURL : serverBaseUrl

      // Set headers
      headers = {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': ct,
        ...headers,
      }

      let data: XMLHttpRequestBodyInit

      if (ct !== XHR_CONTENT_TYPE.MULTIPART) {
        // Convert body to correct format based on contentType
        if (typeof body === 'string' && (ct === XHR_CONTENT_TYPE.URLENCODED || body == '')) {
          data = body
        } else {
          data = JSON.stringify(body)
        }
      } else {
        data = body as FormData
        delete headers['Content-Type'] // Adding an explicit content type causes problems with Multer. Allow the browser to set it.
      }

      return new Promise<APIResponse<Res>>((resolve) => {
        const xhr = new XMLHttpRequest()

        // Handles errors that might occur during or after the request
        const handleError = (status: number, error?: HttpException, callback?: () => void): void => {
          if (error) console.error(error)
          if (callback !== undefined) callback

          dialogStore.actions.pushMessage(
            error?.name || `API error. status: ${status.toString()} code: ${errorCode}`,
            DialogMessageType.Error,
            error && error.message,
            4000,
          )
        }

        // Event listener must be added before calling open()
        xhr.addEventListener('loadend', () => {
          const response: unknown = convertDates ? parseWithDate(xhr.response as string) : xhr.response

          // regular requests must be wrapped in an APIResponse
          const res = {
            status: xhr.status,
            dataType: XHR_CONTENT_TYPE.JSON,
            data: response as Res,
          } as APIResponse<Res>
          if (xhr.status >= 400) {
            const r = response as Record<string,string>
            let exception: HttpException | undefined = undefined
            if (r) {
              exception = new HttpException(xhr.status, `Call to ${route}: ${r?.message || xhr.statusText}`)
              exception.name = r.name
              res.error = exception
            }
            res.data = undefined
            handleError(xhr.status, exception)
          } else if (requiresFeedback) dialogStore.actions.pushMessage(xhr.status.toString(), DialogMessageType.Success, getSuccessTag())
          resolve(res)
        })

        xhr.addEventListener('error', (error: ProgressEvent) => {
          handleError(xhr.status, new HttpException(xhr.status, `Progress error: ${error.type}`), callback)
        })

        let encodedQueries = ''
        if (query !== undefined) {
          // query is an optional parameter
          encodedQueries = base.getEncodedParams(query)
        }

        const url = `${_baseUrl}${route}${encodedQueries}`

        xhr.open(method, url)
        xhr.responseType = 'json'
        xhr.withCredentials = credentials

        if (headers !== undefined) {
          // headers is an optional argument
          for (const key of Object.keys(headers)) {
            xhr.setRequestHeader(key, headers[key])
          }
        }

        try {
          if (method === XHR_REQUEST_TYPE.GET) {
            xhr.send()
          } else {
            xhr.send(data)
          }
        } catch (error: unknown) {
          console.error(error)
        }
      })
    },
  }

  return { base }
}

export default useApiService
